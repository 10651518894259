import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { AuthContext } from "../../components/Context/auth.jsx";
import { Link } from "react-router-dom";

function ModAProv({ dadosAprov, documento, valor, tipo, closeModal }) {
  //Autenticação
  const { customHeaders } = useContext(AuthContext);
  const token = `${customHeaders.Authorization}`;
  const authorizationHeader = `${token}`;

  //Carrega somente os dados do Pedido Selecionado
  const dadosDocumento = dadosAprov.filter((pendencia) => {
    if (pendencia.Pedido && typeof pendencia.Pedido === 'string' && pendencia.Pedido.trim() === documento.trim()) {
      return {
        Pedido: pendencia.Pedido,
        Nome: pendencia.Nome_Aprov,
        Limite: pendencia.Limite_Aprov,
        Projeto: pendencia.Descricao_Projeto,
      };
    } else {
      console.log("Problema ao Procurar pelo Registro");
      return null; // ou outra ação apropriada
    }
  });

  //Carregamento em caso de Aprovação
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //Valida em caso de erro:
  if (error != null) {
    console.log(error);
  }

  //Aciona a API para recuperar dados do Pedido, Cotação e Rateio somente do documento específico para aprovação
  const [dadosPedido, setDadosPedido] = useState([]);
  const [dadosSolicitacao, setDadosSolicitacao] = useState([]);

  useEffect(() => {
    if (tipo === "IP") {
      const fetchData = async () => {
        try {
          const numPed = documento.trim();
          const ScQryApi = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/aprov_qry/sc_qry/${numPed}/`;

          const ScQryApiExec = await fetch(ScQryApi, {
            headers: {
              Authorization: authorizationHeader,
            },
          });

          if (!ScQryApiExec.ok) {
            throw new Error(
              `Erro ao buscar dados do Pedido: ${ScQryApiExec.statusText}`
            );
          }

          const dadosScQry = await ScQryApiExec.json();
          setDadosPedido(
            dadosScQry["Liberacao de Documentos - API Dados do Pedido"]
          );
        } catch (error) {
          console.error("Erro ao buscar dados - Geral:", error);
          setError(error);
        }
      };
      fetchData();
    } else {
      const fetchData = async() => {
        try{
          const numSc = documento.trim()
          const sc1QryApi = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/sc1_qry/sc1_solic/${numSc}/`;

          const sc1QryApiExec = await fetch(sc1QryApi, {
            headers: {
              Authorization: authorizationHeader,
            },
          });

          if (!sc1QryApiExec.ok){
            throw new Error(
              `Erro ao Buscar dados da SC: ${sc1QryApiExec.statusText}`
            );
          }

          const dadosSc1Qry = await sc1QryApiExec.json();

          setDadosSolicitacao(dadosSc1Qry["DetalhamentoSolicitacao"]);

        } catch (error) {
          console.error("Erro ao buscar dados - Geral:", error);
          setError(error);
        }
      };
      fetchData();
    }
  }, [documento, tipo, authorizationHeader]);

  //Tratamento de Dados para Aba Pedido de Compra ou Solicitação
  const dadosPedidoSC = Array.isArray(dadosPedido)
    ? dadosPedido.reduce((acc, linhaAtual) => {
        const chave = `${linhaAtual.Pedido}-${linhaAtual.Item}`;

        if (!acc[chave]) {
          acc[chave] = linhaAtual;
        }

        return acc;
      }, {})
    : {};

  //Tratamento de Dados para Nova Pagina com Mapa de Cotacao
  const dadosMpCot =
    dadosPedido.length > 0
      ? dadosPedido.reduce((acc, linhaAtual) => {
          const chave = `${linhaAtual.FornecedorCot.trim()}-${
            linhaAtual.ItemCot
          }`;
          if (!acc[chave]) {
            acc[chave] = linhaAtual;
          }
          return acc;
        }, {})
      : {};

  //Tratamento de Dados para Aba de Dados Relevantes ao Projeto
  const dadosCc = dadosPedido.length > 0
  ? dadosPedido.reduce((acc, linhaAtual) => {
      const chave = linhaAtual.Centro_custo;
      if (!acc[chave]) {
        acc[chave] = linhaAtual.Centro_custo;
      }
      return acc;
    }, {})
  : dadosSolicitacao.reduce((acc, linhaAtual) => {
      const chave = linhaAtual.custo;
      if (!acc[chave]) {
        acc[chave] = linhaAtual.custo;
      }
      return acc;
    }, {});


  localStorage.setItem("cotacao", JSON.stringify(dadosMpCot));

  //Cria o Json para envio no Aprovar documento Selecionado
  const criarJsonParaAPI = (documento, tipo, codigoAprovador, item) => {

    console.log(codigoAprovador)

    const jsonParaAPI = {
      Documento: documento,
      Tipo: tipo.trim(),
      Aprovador: codigoAprovador.trim(),
      ItemGrupo: item,
    };
    return jsonParaAPI;
  };

  //Aprovar o Documento Selecionado
  const handleClickAprovar = async () => {

      //Dados Aprovador
      let codigoAprovador = dadosAprov[0].Cod_Aprov
      let item = dadosAprov[0].Projeto

      console.log(dadosAprov)
    const jsonParaAPI = criarJsonParaAPI(
      documento,
      tipo,
      codigoAprovador,
      item
    );
    console.log(jsonParaAPI);
    setIsLoading(true);
    try {
      const urlLibdoc = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/custom_libdoc/libdoc`;
      const responseAprovador = await fetch(urlLibdoc, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationHeader,
        },
        body: JSON.stringify(jsonParaAPI),
      });

      if (!responseAprovador.ok) {
        throw new Error(
          `Erro ao buscar dados do aprovador: ${responseAprovador.statusText}`
        );
      }

      const dataAprovador = await responseAprovador.json();
      console.log(dataAprovador);
      closeModal();
    } catch (error) {
      console.error("Erro ao aprovar o pedido:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show id="modal" size="xl" centered onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Detalhes para avaliação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Pedido de Compra ou Solicitação
              </Accordion.Header>
              <Accordion.Body>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="4">
                      <Form.Label>Número do Documento</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={documento}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label>Valor Total</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={valor.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        disabled
                        style={{ textAlign: "right" }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label>Fornecedor/Solicitante</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={
                          dadosPedido.length > 0 &&
                          dadosPedido[0].Fornecedor.trim() !== ""
                            ? dadosPedido[0].Fornecedor
                            : dadosSolicitacao.length > 0 && dadosSolicitacao[0].Solicitante
                        }
                        disabled
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md="4">
                      <Form.Label>Numero da SC/Medição</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={
                          dadosPedido.length > 0 &&
                          dadosPedido[0].Tiposcmed.trim() !== ""
                            ? dadosPedido[0].Tiposcmed
                            : "Não se aplica ou não possue"
                        }
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label>Numero da Cotação</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={
                          dadosPedido.length > 0 &&
                          dadosPedido[0].NumCot.trim() !== ""
                            ? dadosPedido[0].NumCot
                            : "Não se aplica ou não possue"
                        }
                        disabled
                      />
                    </Form.Group>
                  </Row>

                  <Table
                    responsive="sm"
                    striped
                    bordered
                    hover
                    style={{ marginTop: "15px" }}
                  >
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Descrição</th>
                        <th>Quantidade</th>
                        <th>Valor</th>
                        <th>Observação</th>
                        <th>Memorando</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Object.keys(dadosPedidoSC).length > 0 ? (
                        Object.values(dadosPedidoSC).map((item, index) => (
                          <tr key={index}>
                            <td>{item.Item}</td>
                            <td>{item.Descricao}</td>
                            <td>{item.Quantidade}</td>
                            <td style={{ textAlign: "right" }}>
                              {item.Valor.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                            <td>{item.Observacao}</td>
                            <td>{item.Memorando}</td>
                          </tr>
                        ))
                      ) : (
                        dadosSolicitacao.map((dadosSc, index) => (
                          <tr key={index}>
                            <td>{dadosSc.item}</td>
                            <td>{dadosSc.produto}</td>
                            <td>{dadosSc.quantidade}</td>
                            <td>-</td>
                            <td>{dadosSc.obssimples}</td>
                            <td>-</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0" style={{ marginTop: "15px" }}>
            <Accordion.Item eventKey="1">
              <Link
                to={{
                  pathname: `/MapaDeCotacao/${dadosPedido[0]?.NumCot}`,
                }}
                state={{ cotacao: dadosMpCot }}
                target="_blanck"
                style={{ textDecoration: "none" }}
              >
                <Accordion.Header>
                  Visualizar Mapa de Cotação (Nova Página)
                </Accordion.Header>
              </Link>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Accordion defaultActiveKey="0" style={{ marginTop: "15px" }}>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Dados Relevantes ao Projeto</Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Número do Documento</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={documento}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Valor Centro de Custo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={valor.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                      disabled
                      style={{ textAlign: "right" }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Centro de Custos no Pedido</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(dadosCc).map((custo, index) => (
                        <tr key={index}>
                          <td>{custo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0" style={{ marginTop: "15px" }}>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Dados do Aprovador</Accordion.Header>
            <Accordion.Body>
              <Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>Nome do Aprovador</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      dadosDocumento.length > 0 &&
                      dadosDocumento[0].Nome_Aprov.trim() !== ""
                        ? dadosDocumento[0].Nome_Aprov
                        : "Favor chamar o T.I"
                    }
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Saldo disponível para Aprovação</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      dadosDocumento.length > 0 &&
                      typeof dadosDocumento[0].Limite_Aprov === "string"
                        ? dadosDocumento[0].Limite_Aprov.toLocaleString(
                            "pt-BR",
                            { style: "currency", currency: "BRL" }
                          )
                        : dadosDocumento[0].Limite_Aprov.toLocaleString(
                            "pt-BR",
                            { style: "currency", currency: "BRL" }
                          )
                    }
                    disabled
                    style={{ textAlign: "right" }}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Projeto da Aprovação Atual</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      dadosDocumento.length > 0 &&
                      dadosDocumento[0].Descricao_Projeto.trim() !== ""
                        ? dadosDocumento[0].Descricao_Projeto
                        : "Favor chamar o T.I"
                    }
                    disabled
                  />
                </Form.Group>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-success"
          onClick={handleClickAprovar}
          disabled={isLoading}
        >
          {isLoading ? "Aprovando..." : "Aprovar"}
        </Button>
        <Button variant="outline-secondary" onClick={closeModal}>
          Continuar Depois
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModAProv;
