import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../components/css/Dashboard.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PiWarehouseDuotone } from "react-icons/pi";
import { BsCheck2Square } from "react-icons/bs";
import { IoIosLaptop } from "react-icons/io";
import Header from "./header.jsx";
import Footer from "./footer.js";

export default function Dashboard() {
  return (
    <div>
      <Header />

      <h1 className="text-center mt-4 mb-5">Módulos</h1>

      <Container className="d-flex align-items-center justify-content-center">
        <Row>
          <Col md={4}>
            <Card style={{ width: "18rem" }}>
              <Card.Body className="d-flex flex-column align-items-center">
                <BsCheck2Square color="#018896" size={50} />
                <Card.Title>Aprovação</Card.Title>
                <Card.Text>Rotina de Aprovação Protheus</Card.Text>
                <Link to="/Aprovar">
                  <Button className="w-100 btnModulo">Acessar Módulo</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card style={{ width: "18rem" }}>
              <Card.Body className="d-flex flex-column align-items-center">
                <IoIosLaptop color="#018896" size={50} />
                <Card.Title>Ativo fixo</Card.Title>
                <Card.Text>Rotina de ativos fixo</Card.Text>
                <Link to="/tipoativo">
                  <Button className="w-100 btnModulo">
                    Acessar Módulo
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card style={{ width: "18rem" }} id="ALMOX">
              <Card.Body className="d-flex flex-column align-items-center" >
                <PiWarehouseDuotone color="#018896" size={50} />
                <Card.Title>Almoxarifado</Card.Title>
                <Card.Text>Controle de Estoques</Card.Text>
                <Link to="/estoques">
                  <Button className="w-100 btnModulo">
                    Acessar Módulo
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
      <Footer />
    </div>
  );
}
