import React, { useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DtBase from './dtbase.js';
import Header from "../header.jsx";
import { useAprovData } from '../../components/hooks/FetchSearch.ts';
import { useContext } from 'react';
import { AuthContext } from '../../components/Context/auth.jsx';

function Search() {
  const [updateSearch, setUpdateSearch] = useState(false);
  const [pedido, setPedido] = useState('');
  const [projeto, setProjeto] = useState('');

  //Autenticação
  const { customHeaders, user } = useContext(AuthContext);
  const token = `${customHeaders.Authorization}`;
  const authorizationHeader = `${token}`;
  const codUser = `${user.id}`;
  
  //Dados da Aprovação
  const AprovQry = useAprovData(codUser, authorizationHeader);
  const AprovData = AprovQry.data && AprovQry.data["Liberacao de Documentos - API Geral"];
  
  //Dados do Projeto
  const Projetos = new Set(AprovData && AprovData.map(AprovData => AprovData.Descricao_Projeto));
  const ProjetosUnicos = Array.from(Projetos);

  useEffect(() => {
    AprovQry.refetch()
  }, [updateSearch]);

  const refetchData = useCallback(() => {
    setUpdateSearch(prevState => !prevState);
  }, []);

  return (
    <div style={{ marginRight: '0', marginLeft: '0', width: '100%', maxWidth: "100%", paddingLeft: "0", paddingRight: "0" }}>
      <Header />
      <Form style={{ width: '90%', marginLeft: "1rem" }}>
        <Row className="mb-3" style={{ 'marginTop': '15px', 'marginBottom': '15px' }}>
          <Col xs={12} md={4}>
            <Form.Group controlId="crNumPed">
              <Form.Label style={{ fontWeight: "bold", }}>Número do Pedido</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ex: 059651"
                name="pedido"
                value={pedido}
                onChange={(e) => setPedido(e.target.value)}
                style={{ marginLeft: "5px" }}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={4} >
            <Form.Group controlId="crProject">
              <Form.Label style={{ fontWeight: "bold", marginLeft: "2px" }}>Selecione o Projeto:</Form.Label>
              <Form.Select
                name="projeto"
                value={projeto}
                onChange={(e) => setProjeto(e.target.value)}
              >
                <option value="">Todos</option>
                {ProjetosUnicos && ProjetosUnicos.map((projetos, index) => (
                  <option key={index} value={projetos}>{projetos}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

        </Row>
      </Form>

      <DtBase AprovData={AprovData} ProjetoSelecionado={projeto} PedidoSelecionado={pedido} refetchData={refetchData} />

    </div>
  );
}

export default Search;
